<template>
  <section class="chartjs">
    <div class="page-header">
      <h3 class="page-title">
        Ventes
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">E-commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Vente</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Moyenne journalière</h4>
            <line-chart :height="250"></line-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <h4 class="card-title">Moyenne mensuelle</h4>
              <line-chart :height="250"></line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Moyenne annuelle</h4>
            <line-chart :height="250"></line-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <h4 class="card-title">Produit les plus vendus</h4>
              <doughnut-chart :height="200"></doughnut-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <h4 class="card-title">Produit les plus vendus</h4>
              <doughnut-chart :height="200"></doughnut-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <h4 class="card-title">Produit les moins vendus</h4>
              <doughnut-chart :height="200"></doughnut-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <h4 class="card-title">Produit les mieux notés</h4>
              <doughnut-chart :height="200"></doughnut-chart>
          </div>
        </div>
      </div>
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <h4 class="card-title">Produit les moins notés</h4>
              <doughnut-chart :height="200"></doughnut-chart>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import lineChart from '../../../components/charts/chartjs/lineChart'
import doughnutChart from '../../../components/charts/chartjs/doughnutChart'
export default {
  name: 'chartjs',
  components: {
    lineChart,
    doughnutChart
  }
}
</script>
